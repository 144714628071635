<template>
    <div class="share" ref="share">
        <!-- 头部导航 -->
        <HeaderBar title="Share" style="z-index:99999" />
        <div class="shareBox">
            <div id="capture">
                <div class="capture-img">
                    <img class="share-bg" :src="goodsImg" alt="">
                    <div class="top-tip">
                        <img src="@/assets/bargain/share-tip.png" alt="">
                    </div>
                </div>
                <div class="code-tips">
                    <div class="tips">
                        <p>https://m.moobyyoho.com/en...</p>
                        Hey! I'm getting discounted 2022 Richmond Night Market Express Pass now.  
                    </div>
                    <div class="copy" @click="copy" :data-clipboard-text="urls">Click to copy link</div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import HeaderBar from '@/components/zh/m-header.vue'
import Clipboard from 'clipboard'
import { getImg } from '@/api/zh/detail.js'

export default {
    name: 'share',
    components: { HeaderBar },
    data(){
        return {
            shareData:{},
            goodsImg: '',
            imgSrc: '',
            isShow: false,
            goods: {},
            url: ''
        }
    },
    methods:{
        getImgHandle() {
            getImg(this.goods.id,{barcode: this.goods.barcode}).then(res => {
                if(res) {
                    this.goodsImg = res.data
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        // 点击复制
        copy() {
            var clipboard = new Clipboard('.copy')
            clipboard.on('success', () => {
               this.$toast('Done!')
            })
        }
    },
    created() {
        this.$store.commit('load')
        this.goods = JSON.parse(sessionStorage.getItem('bargainShare'))
        this.urls = '👉 https://m.moobyyoho.com/en/help?id=' + this.$route.query.id + " Hey! I'm getting discounted 2022 Richmond Night Market Express Pass now. Click the link above to give me a hand to get more discounts please!   "
        this.getImgHandle()
    },
}
</script>
<style lang="less" scope>
.share {
    position: relative;
    width:100%;
    height:100%;
    overflow: hidden;
    .shareBox{
        position: absolute;
        top:12%;
        left:50%;
        height: 100%;
        transform: translateX(-50%);
        overflow: hidden;
        border-radius: 12px;
        #capture{
            width: 310px;
            // overflow: visible !important;
            font-family: Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei', SimHei;
            .capture-img {
                width: 100%;
                height: 360px;
                border-radius: 12px 12px 5px 5px;
                position: relative;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                .share-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .top-tip {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 49px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .code-tips {
                width: 298px;
                position: relative;
                margin: 10px auto 0;
                .tips {
                    width: 298px;
                    height: 140px;
                    padding: 18px 0 0 14px;
                    // text-align: center;
                    font-size: 16px;
                    background: url('../../../assets/bargain/share.png') no-repeat;
                    background-size: 100% 100%;
                    p {
                        color: #35B6FF;
                    }
                }
                .copy {
                    position: absolute;
                    bottom: -28px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 193px;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    font-size: 12px;
                    background: linear-gradient(90.2deg, #FFBC9E 0.14%, #FFCEB8 101.07%);
                    box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.5);
                    border-radius: 30px;
                }
            }
        }
    }
    .van-popup {
        padding: 10px 10px;
        border-radius: 20px;
    }
}
</style>